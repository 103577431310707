div.about {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @include highlighted-link;
  @include mobile {
    div {
      margin: 2rem;
      font: $text;
      p {
        width: calc(100% - 4rem);
      }
      a,
      a:hover,
      a:active,
      a:visited,
      a:focus {
        font: $text;
      }
      p.hi {
        font: $heading-1;
        margin-bottom: 0;
      }
      p.me {
        font: $heading-2;
        margin-bottom: 40px;
      }
    }
  }

  @include tablet-desktop-wide {
    div {
      max-width: 88rem;
      width: 100%;
      margin: 4rem;
      font: $about;
      p {
        margin-bottom: 4rem;
      }
      a {
        font: $about;
      }
      a,
      a:active,
      a:visited,
      a:focus {
        box-shadow: inset 0 -12px 0 0 $highlight-color;
        transition: box-shadow .1s ease-in-out;
      }
      p.hi {
        font: $hi;
        margin-bottom: 0;
      }
      p.me {
        font: $me;
      }
    }
  }
}

.posts {
  @include mobile {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }

  @include tablet-desktop {
    margin: 4rem;
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 2rem;
  }

  @include wide {
    margin: 4rem;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 2rem;
  }

  .post {
    @include tablet-desktop-wide {
      position: relative;
      overflow: hidden;
      background: $text-color;
      margin-bottom: 2rem;
      flex: 50%;
      @include rounded-border;
      &:hover {
        .image {
          transform: scale(1);
          opacity: 0.24;
        }
        .overlay {
          opacity: 1;
          .text {
            h1 {
              transform: translate3d(0, 0, 0);
            }
            div {
              transform: translate3d(0, 0, 0);
            }
          }
        }
      }
    }

    .image {
      display: block;
      width: 100%;
      height: auto;
      @include tablet-desktop-wide {
        transform: scale(1.1);
        transition: 0.3s ease-in-out;
        opacity: 1;
      }
    }
    .overlay {
      @include mobile {
        display: none;
      }

      @include tablet-desktop-wide {
        display: flex;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: 0.3s ease;
        .text {
          margin: auto auto 2.4rem 2.4rem;
          h1 {
            color: white;
            text-align: left;
            text-transform: uppercase;
            font: $desktop-project-link;
            transition: transform 0.55s;
            transform: translate3d(-4rem, 0, 0);
          }
          div {
            color: white;
            text-align: left;
            font: $sub-text;
            line-height: 1.4rem;
            transition: transform 0.65s;
            transform: translate3d(-4rem, 0, 0);
          }
        }
      }
    }
  }
  .mobile-title {
    @include mobile {
      display: block;
      margin: 1rem 0 1.7rem 2rem;
      text-align: left;
      a {
        font: $mobile-project-link;
        color: $text-color !important;
        text-transform: uppercase;
      }
      p {
        font: $sub-text;
        margin: 0;
      }
    }

    @include tablet-desktop-wide {
      display: none;
    }
  }
}
