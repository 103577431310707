div.post-hidden-header-holder {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $bg-color;
  border-bottom: solid 1px $disabled-color;
  header.post {
    max-width: 1280px;
    width: 100%;
  }
  @include mobile {
    transform: translate3d(0, -10.9rem, 0);
  }

  @include tablet-desktop-wide {
    transform: translate3d(0, -16.9rem, 0);
  }
}

.post-holder {
  max-width: 67.5rem;
  @include mobile {
    margin: 0 2rem;
  }

  @include tablet-desktop-wide {
    margin: 4rem auto;
  }

  p {
    margin-bottom: 4rem;
    @include highlighted-link;
  }
  img {
    width: calc(100% + 4rem);
    margin-left: -2rem;
    @include rounded-border;
    @include tablet-desktop-wide {
      width: 100%;
      margin-left: 0;
      &.max-width {
        max-width: 64rem;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }
  }
  ul {
    margin-bottom: 4rem;
  }
  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    margin-bottom: 4rem;
  }
  .videoWrapper iframe {
    @include rounded-border;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  a.back-button,
  a.back-button:hover,
  a.back-button:active,
  a.back-button:visited,
  a.back-button:focus {
    color: $text-color;
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 4rem 0;
    animation: none;
    &:hover {
      animation: none;
    }
    img {
      height: 2.4rem;
      width: auto;
      margin: 0 1rem 0 0;
    }
  }
  .date {
    font: $sub-text;
    font-size: 1.8rem;
    line-height: 4rem;
    margin-bottom: 4rem;
  }
}
