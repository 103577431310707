// media queries

@mixin mobile {
  @media (max-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-breakpoint) and (max-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-breakpoint) and (max-width: $wide-screen-breakpoint) {
    @content;
  }
}

@mixin wide {
  @media (min-width: $wide-screen-breakpoint) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin tablet-desktop {
  @media (min-width: $tablet-breakpoint) and (max-width: $wide-screen-breakpoint) {
    @content;
  }
}

@mixin desktop-wide {
  @media (min-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin tablet-desktop-wide {
  @media (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin highlighted-link {
  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    color: $text-color;
    outline: 0;
    box-shadow: inset 0 -7px 0 0 $highlight-color;
    transition: box-shadow .2s ease-in-out;
    text-decoration: none;
  }
  a:hover {
    box-shadow: inset 0 -40px 0 0 $highlight-color;
  }
}

// animations

@keyframes dim {
  from {
    color: $text-color;
  }
  to {
    color: $disabled-color;
  }
}

@keyframes glow {
  from {
    color: $disabled-color;
  }
  to {
    color: $text-color;
  }
}

@mixin glowing-link {
  @include desktop-wide {
    animation: dim 0.3s ease-in 1 forwards;
    &:hover {
      animation: glow 0.3s ease-in 1 forwards;
    }
  }
}

// reusable properties

@mixin gradient-line {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(116, 230, 133, 0),
    rgba(116, 230, 133, 0.75),
    rgba(116, 230, 133, 0)
  );
}

@mixin line {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

@mixin rounded-border {
  @include tablet-desktop-wide {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }
}
