$bg-color: white;
$text-color: #222222;
$disabled-color: #828282;
$highlight-color: #FEED00;

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,700");

// 1rem = 62.5% of 16px = 10px
// general
$heading-1: 700 4.8rem / 5.6rem "Open Sans", sans-serif;
$heading-2: 600 2.4rem / 3.6rem "Open Sans", sans-serif;
$text: 400 1.8rem / 3.2rem "Open Sans", sans-serif;
$sub-text: 300 1.4rem / 2rem "Open Sans", sans-serif;

// landing
$hi: 700 7.2rem "Open Sans", sans-serif;
$me: 600 4.8rem "Open Sans", sans-serif;
$about: 400 3.2rem / 6.4rem "Open Sans", sans-serif;
$mobile-project-link: 600 2rem "Open Sans", sans-serif;
$desktop-project-link: 600 2.2rem / 4rem "Open Sans", sans-serif;

// media-queries do not consider font-size; 50em = 16*50 = 800px
// 840px
$tablet-breakpoint: 52.5em;

// 1080px
$desktop-breakpoint: 67.5em;

// 1400px
$wide-screen-breakpoint: 87.5em;
