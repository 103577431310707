html {
  font-size: 62.5%;
}

.only-mobile {
  @include mobile {
    display: block;
  }

  @include tablet-desktop-wide {
    display: none;
  }
}

.only-not-mobile {
  @include mobile {
    display: none;
  }

  @include tablet-desktop-wide {
    display: block;
  }
}

.only-mobile-tablet {
  @include mobile-tablet {
    display: block;
  }

  @include desktop-wide {
    display: none;
  }
}

.only-desktop-wide {
  @include mobile-tablet {
    display: none;
  }

  @include desktop-wide {
    display: block;
  }
}

body {
  background-color: $bg-color;
  color: $text-color;
  font: $text;
  overflow-x: hidden;
  p {
    margin: 0 0 2rem 0;
    @include tablet-desktop-wide {
      padding: 0;
    }
  }
  h1 {
    font: $heading-1;
    margin: 0;
    text-transform: uppercase;
  }
  h2 {
    font: $heading-2;
  }
  ul {
    list-style: circle;
  }
  em {
    font-style: italic;
  }
}

div.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  div.holder {
    max-width: 1360px;
    width: 100%;
    header {
      @include mobile {
        padding: 4rem 2rem;
      }

      @include tablet-desktop-wide {
        padding: 4rem;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;

      div.links {
        @include highlighted-link;
        a.only-mobile {
          @include mobile {
            display: flex;
            img {
              height: 3.6rem;
            }
          }
        }
      }

      div.logo {
        a {
          img {
            height: 6rem;
          }
        }
      }
    }

    footer {
      font: $sub-text;
      @include mobile {
        margin: 4rem 0;
      }

      @include tablet-desktop-wide {
        margin: 8rem 4rem;
      }

      .footer-divider {
        @include mobile {
          width: 100%;
        }

        @include tablet-desktop-wide {
          width: calc(100%-4rem);
        }

        height: 1px;
        display: block;
        background-color: $disabled-color;
        border: 0;
        margin-bottom: 1rem;
      }
      .wrapper {
        display: flex;
        justify-content: space-between;
        .copyright {
          flex: 1;
          @include mobile {
            margin-left: 2rem;
          }
        }
        .social-links {
          width: 9rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include mobile {
            margin-right: 2rem;
          }

          a {
            img {
              width: 2.4rem;
            }
          }
        }
      }
    }
  }
}
